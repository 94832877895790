import React from "react"
import LayoutDay from "../../../components/Chronology/LayoutDay"
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft"
import ContentRight from "../../../components/Chronology/Modules/ContentRight"
import InlineLink from "../../../components/Chronology/Modules/InlineLink"
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable"
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu"
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine"
import ModImage from "../../../components/Chronology/Modules/ModImage"
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu"
import ModText from "../../../components/Chronology/Modules/ModText"

export const frontmatter = {
  title: "Día 101",
  week: "Semana 15",
  day: "22",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-22",
  path: "/cronologia/semana-15#dia-22-jun/",
}

const Day101 = props => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en <strong>España</strong> 1.520 casos
          mediante pruebas PCR. En ese mismo periodo, 97 personas han requerido hospitalización
          (6,4% de los diagnósticos), de los que ocho han sido ingresos en la UCI, y se han
          producido 40 fallecimientos.
        </ModText>
        <ModText>
          El Gobierno ha publicado las{" "}
          <InlineLink link="https://administracion.gob.es/pag_Home/atencionCiudadana/Nueva-normalidad-crisis-sanitaria.html#-57105872f063">
            medidas de prevención, contención y coordinación
          </InlineLink>{" "}
          para seguir haciendo frente y controlando a COVID-19 durante la Nueva Normalidad.
        </ModText>
        <ModText>
          También ha publicado una{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/transicion-ecologica/Documents/2020/220620-guia-climatizacion-anticovid.pdf">
            Guía con recomendaciones
          </InlineLink>{" "}
          sobre el uso y mantenimiento de los sistemas de climatización y ventilación de edificios y
          locales comerciales para prevenir la expansión del COVID-19.
        </ModText>
        <ModText>
          Un equipo de investigadores del Consejo Superior de Investigaciones Científicas (CSIC)
          lidera un proyecto para desarrollar fármacos antivirales que impidan la replicación del
          coronavirus SARS-CoV-2, causante de la pandemia de Covid-19.
        </ModText>
        <ModImage
          src="/images/svg/laboratorio-analisis-investigacion.svg"
          alt="investigación covid"
        />

        <ModText>
          El estudio se centra en las denominadas DIPs (Partículas Defectivas Interferentes), un
          tipo frecuente de parásitos moleculares específico de los virus de ARN, sin capacidad
          infectiva por sí solos pero que se aprovechan de su maquinaria para replicarse e
          interferir así en la infección.
        </ModText>
        <ModText>
          Las peticiones de asistencia a víctimas de violencia de género durante el estado de alarma
          se han elevado a 29.700, un 57,9% más que el año anterior
          <ModImage
            src="/images/svg/22_jun_telefonos.svg"
            alt="asistencia victimas violencia de género"
          />
        </ModText>
      </ContentRight>
    </LayoutDay>
  )
}
export default Day101
